@import './variables';

@font-face {
  font-family: ProximaNova-light;
  font-weight: $font-weight-base;
  font-style: normal;
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-300.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-300.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-300.ttf')
      format('truetype');
}

@font-face {
  font-family: ProximaNova-Regular;
  font-weight: $font-weight-base;
  font-style: normal;
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-400.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-400.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-400.ttf')
      format('truetype');
}

@font-face {
  font-family: ProximaNova-Semibold;
  font-style: normal;
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-600.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-600.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-600.ttf')
      format('truetype');
}

@font-face {
  font-family: ProximaNova-Bold;
  font-weight: $font-weight-base;
  font-style: normal;
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.ttf')
      format('truetype');
}
