@import '../../styles/navigation';
@import '../../styles/variables';
@import './../../styles/common';
@import '../../components/HeaderView/header';
@import '../../components/CommunityView/community';
@import '../../components/FooterView/footer';
@import '../../styles/mesh';

@import 'error__small';

@media only screen and (width >= 1024px) {
  @import 'error__large';
}

.section-error {
  .andes-technical-error__container {
    height: 70vh;
  }
}
