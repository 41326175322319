@import '~@andes/technical-error/index';
@import '~@andes/badge/index';
@import '~@andes/button/base';
@import '~@andes/button/lib/styles/modifier/transparent';
@import '~@andes/snackbar/index';
@import '~@andes/thumbnail/base';
@import '~@andes/thumbnail/lib/styles/modifier/circle';
@import '~@andes/typography/index';

html,
body {
  background-color: #f5f5f5;
}

.nav-header .nav-header-menu-wrapper .nav-search {
  display: none;
}

.nav-header .nav-bounds.nav-bounds-with-cart [for='nav-header-menu-switch'] {
  display: none;
}

.section-error {
  .andes-technical-error__action-button {
    position: static;
  }

  .andes-typography--size-m.andes-typography--type-title {
    font-size: 20px;
  }
}
